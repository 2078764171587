import React from "react";
import { IconProps } from "../icon";

export const Speedometer_04Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17.745 16a7.026 7.026 0 001.094-5.5M6.255 16a7 7 0 016.982-10.891M16.5 7.5L12 12m10 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-9 0a1 1 0 11-2 0 1 1 0 012 0z"
    />
  </svg>
);
