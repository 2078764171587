import React from "react";
import { IconProps } from "../icon";

export const Loading_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3.34 17a4.773 4.773 0 011.458-6.34l.002-.002a4.778 4.778 0 015.484.094l3.432 2.496a4.778 4.778 0 005.485.094l.002-.002A4.77 4.77 0 0020.66 7m-3.658 13.66a4.774 4.774 0 01-6.34-1.458l-.002-.003a4.778 4.778 0 01.095-5.484l2.495-3.432a4.778 4.778 0 00.094-5.484l-.004-.002A4.772 4.772 0 007 3.34M19.07 4.93c3.906 3.905 3.906 10.236 0 14.141-3.905 3.906-10.236 3.906-14.141 0-3.905-3.905-3.905-10.236 0-14.141 3.905-3.905 10.236-3.905 14.141 0z"
    />
  </svg>
);
