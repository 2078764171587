import React from "react";
import { IconProps } from "../icon";

export const Toggle_01RightIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 17H7A5 5 0 017 7h10m0 10a5 5 0 000-10m0 10a5 5 0 010-10"
    />
  </svg>
);
