import React from "react";
import { IconProps } from "../icon";

export const Translate_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 8l5 5m-6 1l6-6 2-3M2 5h12M7 2h1m4.913 15h7.174m-7.174 0L11 21m1.913-4l2.865-5.991c.231-.483.347-.724.505-.8a.5.5 0 01.434 0c.158.076.274.317.505.8L20.087 17m0 0L22 21"
    />
  </svg>
);
