import React from "react";
import { IconProps } from "../icon";

export const LogOut_03Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M16 17l5-5m0 0l-5-5m5 5H9m3 5c0 .296 0 .443-.011.571a3 3 0 01-2.404 2.686c-.126.025-.273.042-.567.074l-1.021.114c-1.535.17-2.302.256-2.911.06a3 3 0 01-1.825-1.633C3 18.288 3 17.516 3 15.972V8.028c0-1.544 0-2.316.261-2.9a3 3 0 011.825-1.634c.61-.195 1.376-.11 2.91.061l1.022.114c.294.032.441.049.567.074a3 3 0 012.404 2.686c.011.128.011.275.011.57"
    />
  </svg>
);
