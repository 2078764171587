import React from "react";
import { IconProps } from "../icon";

export const Toggle_03LeftIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M2 12a6 6 0 016-6h8a6 6 0 010 12H8a6 6 0 01-6-6z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 14.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
    />
  </svg>
);
