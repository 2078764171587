import React from "react";
import { IconProps } from "../icon";

export const Pin_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.377 15.616L2.72 21.273m8.974-14.631l-1.56 1.56a2.1 2.1 0 01-.264.242 1 1 0 01-.207.111c-.082.032-.17.05-.347.085l-3.665.733c-.952.19-1.428.286-1.65.537a1 1 0 00-.243.8c.046.333.39.677 1.076 1.363l7.086 7.086c.686.687 1.03 1.03 1.362 1.076a1 1 0 00.801-.242c.251-.223.346-.7.537-1.651l.733-3.665c.035-.176.053-.265.085-.347a.997.997 0 01.11-.207c.051-.072.115-.136.242-.263l1.561-1.561c.082-.082.122-.122.167-.158.04-.031.082-.06.126-.085.05-.029.103-.051.208-.097l2.495-1.069c.727-.312 1.091-.467 1.256-.72a1 1 0 00.144-.747c-.06-.295-.34-.575-.9-1.135l-5.142-5.143c-.56-.56-.84-.84-1.135-.9a1 1 0 00-.748.145c-.252.165-.407.529-.72 1.256l-1.068 2.495a2.096 2.096 0 01-.097.208.998.998 0 01-.085.126 2.065 2.065 0 01-.158.167z"
    />
  </svg>
);
