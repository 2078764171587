import React from "react";
import { IconProps } from "../icon";

export const LifeBuoy_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8.464 8.464L4.93 4.93m0 14.142l3.535-3.536m7.072 0l3.535 3.536m0-14.142l-3.536 3.535M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-5 0a5 5 0 11-10 0 5 5 0 0110 0z"
    />
  </svg>
);
