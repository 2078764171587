import React from "react";
import { IconProps } from "../icon";

export const HelpHexagonIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9.09 9a3 3 0 015.83 1c0 2-3 3-3 3m.08 4h.01M3 7.941v8.117c0 .343 0 .515.05.667a1 1 0 00.215.364c.109.119.258.202.558.368l7.4 4.111c.284.158.425.237.575.267.133.028.27.028.403 0 .15-.03.292-.11.576-.267l7.4-4.11c.3-.167.45-.25.558-.369a1 1 0 00.215-.364c.05-.152.05-.324.05-.667V7.941c0-.342 0-.514-.05-.666a1 1 0 00-.215-.364c-.109-.119-.258-.202-.558-.368l-7.4-4.111c-.284-.158-.425-.237-.575-.268a1 1 0 00-.403 0c-.15.031-.292.11-.576.268l-7.4 4.11c-.3.167-.45.25-.558.369a1 1 0 00-.215.364C3 7.427 3 7.599 3 7.94z"
    />
  </svg>
);
