import React from "react";
import { IconProps } from "../icon";

export const Toggle_01LeftIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 17h10a5 5 0 000-10H7m0 10A5 5 0 017 7m0 10A5 5 0 007 7"
    />
  </svg>
);
