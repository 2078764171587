import React from "react";
import { IconProps } from "../icon";

export const Tool_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15.631 7.631c-.396-.396-.594-.594-.668-.822a1 1 0 010-.618c.074-.228.272-.426.668-.822L18.47 2.53a6 6 0 00-8.3 6.895c.12.49.179.734.168.888a.849.849 0 01-.11.392c-.07.138-.207.274-.48.547L3.5 17.5a2.121 2.121 0 003 3l6.248-6.248c.273-.273.41-.41.547-.48a.849.849 0 01.392-.11c.154-.011.399.049.888.168a6 6 0 006.895-8.3L18.63 8.37c-.396.396-.594.594-.822.668a1 1 0 01-.618 0c-.228-.074-.426-.272-.822-.668l-.738-.738z"
    />
  </svg>
);
