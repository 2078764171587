import React from "react";
import { IconProps } from "../icon";

export const LinkBroken_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 4V2m6 18v2M4 9H2m18 6h2M4.914 4.914L3.5 3.5m15.586 15.586L20.5 20.5M12 17.657l-2.121 2.121a4 4 0 11-5.657-5.657L6.343 12m11.314 0l2.121-2.121a4 4 0 10-5.657-5.657L12 6.343"
    />
  </svg>
);
