import React from "react";
import { IconProps } from "../icon";

export const Target_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22 12h-4M6 12H2m10-6V2m0 20v-4m8-6a8 8 0 11-16 0 8 8 0 0116 0z"
    />
  </svg>
);
