import React from "react";
import { IconProps } from "../icon";

export const Link_01Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12.708 18.364l-1.415 1.414a5 5 0 11-7.07-7.07l1.413-1.415m12.728 1.414l1.415-1.414a5 5 0 00-7.071-7.071l-1.415 1.414M8.5 15.5l7-7"
    />
  </svg>
);
